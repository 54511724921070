@import '../../settings/main';

.button-q {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;

  &_hover {
    .button-q__plus, .button-q__minus {
      opacity: 1;
    }

    .button-q__btn {
      @include border--default-light-gray;
      background: white;
    }
  }

  &:hover {
    .button-q__plus, .button-q__minus {
      opacity: 1;
    }

    .button-q__btn {
      @include border--default-light-gray;
      background: white;
    }
  }

  span {
    padding-right: 7px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__plus {
    opacity: 0;
    width: 20px;
    height: 40px;
    flex-shrink: 0;
  }

  &__minus {
    opacity: 0;
    width: 20px;
    height: 40px;
    flex-shrink: 0;
  }

  &__btn {
    height: 38px;
    width: 100%;
  }

  &__select {
    position: absolute;
    top: 40px;
    text-align: left;
    z-index: 1;
  }

  &__dropdown {
    @include text--semi-bold-big;

    width: 139px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;

    padding-top: 13px;
    padding-bottom: 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius--default;
    line-height: 20px;
    letter-spacing: $letter-spacing-small;

    div {
      padding-left: 15px;
      cursor: pointer;
      &:hover {
        border-left: 3px solid $color--yellow;
      }
      span {
        @include text--regular-big;
        color: $color--dark-gray;
      }
    }
  }

  &__input {
    width: 100%;
    text-align: center;
    padding: 0 3px;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@import '../../settings/main';