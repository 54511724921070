.main-page {
	width: 100%;
	flex: 1 1 auto;
	padding: 15px 5px 15px 15px;

	&__widget {
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}