@import '../../settings/main';

.order {
  display: flex;
  width: 1280px;
  margin: 0 auto;
  min-height: 100%;

  background: white;
  box-shadow: 0 74px 100px rgba(0, 0, 0, 0.2);

  &__container {
    flex: none;
    width: 912px;
  }

  &__details {
    width: 368px;
    flex: none;
  }

  .basket-download-btn {
    display: flex;
    margin-top: 15px;
    padding: 0 20px;
    column-gap: 20px;

    &__item {
      display: flex;
      align-items: center;
      column-gap: 10px;
      span {
        @include text--regular-small;
        color: $color--dark-gray;
      }
    }
  }
}