@import "../../settings/main";

.file-upload {
  width: 310px;
  padding: 20px;

  button {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    @include text--bold-large;
    margin-bottom: 16px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__input {
    display: none;
  }

  &__file-name {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
  }
}

.file-upload-finish {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #808080;

    img {
      margin-left: 6px;
    }
  }

  &__failed-list {
    font-size: 12px;
    line-height: 14px;
    color: #808080;
    margin: 5px 0 15px;
  }
}