@import '../../settings/main';


.table-tools {
  display: flex;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  background: white;

  .download-block {

    span {
      margin-left: 5px;
    }

    &__title {
      @include text--regular-x-small;
      color: $color--dark-gray;
    }

    &__tools {
      display: flex;
      column-gap: 15px;
      @include text--semi-bold-small;
      margin-top: 8px;
    }

    &__tools-item {
      display: inline-flex;
      align-items: center;
    }
  }
}


.element-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  &__title {
    @include text--regular-big;
    margin-right: 10px;
    color: $color--dark-gray
  }

  &__select {
    height: 100%;
    width: 80px;

    span {
      padding-right: 27px;
    }
  }
  &__dropdown {
    width: 80px;
  }
}

.paginator {
  @include border--default-light-gray;
  height: 40px;
  display: flex;
  background: none;
  cursor: pointer;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    &:hover {
      background-color: $color--white-gray;
    }
  }

  &__back, &__next {
    display: flex;
    align-items: center;
    width: 90px;
    height: 100%;
  }

  &__back {
    padding-left: 32px;
    border-right: $border--light-gray;
    background: url('../../assets/svg/LeftArrowLight.svg') no-repeat center left 17.67px;
  }

  &__next {
    background: url('../../assets/svg/RightArrowLight.svg') no-repeat center right 16.33px;
    padding-left: 16px;
  }

  &__page {
    width: 50px;
    height: 100%;
    border-right: $border--light-gray;
  }

  &__page_active {
    background-color: #ebebeb !important;
    box-shadow: inset 0 3px 6px rgb(0 0 0 / 5%);
  }
}