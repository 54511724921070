@import '../../settings/main';

.basket {
  //height: calc(100vh - 120px);

  &__order-form-modal {
    z-index: 2;
  }

  &__wrapper {
    display: flex;
    height: 100%;

    .basket-content {
      padding: 15px;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      &__item {
        margin-bottom: 10px;
      }
    }
  }
}

