@import '../../settings/main';

.checkbox-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 15px;
  width: 15px;
  border: 1px solid #E0E2E5;
  cursor: pointer;

  &:checked {
    border: 1px solid #FFCD1A;
    background: $color--yellow url("../../assets/svg/ico/checkedBlack.svg") no-repeat center;
    background-size: 8.87px 7.28px;
  }
}

.toggle-input {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 25px;
  height: 13px;
  vertical-align: top;
  border: 1.5px solid $color--light-gray;
  border-radius: 6.5px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 0;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: white;

    border: 1.5px solid $color--dark-gray;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    top: -1.5px;
    left: -2px;

    transform: translateX(0);
  }

  &:checked::after {
    transform: translateX(100%);
    background-color: $color--yellow;
    border: 1.5px solid black;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  &:checked {
    background: white;
  }
}
