@import '../../settings/main';

.button-accordion {
  @include text--bold-xx-large;
  display: flex;
  align-items: center;
  column-gap: 14px;
  letter-spacing: 0.02em;
  line-height: 30px;
  cursor: pointer;

  &__ico {
    display: inherit;
    align-items: inherit;
    height: 20px;
  }
}