@import "../../settings/main";

.order-details {
  height: 100%;
  width: 100%;
  padding: 24px 19px 0 20px;
  border-left: $border--light-gray;
  background: $color--white-gray;
  &__title {
    @include text--bold-xx-large;
  }
  .table {
    margin-top: 21px;
    &__row {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $border--light-gray;

      .wow {
        color: $color--purple;
      }
    }
  }
}










