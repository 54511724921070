@import '../../settings/main';

.loader-container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.1);
  z-index: 999;

  &__loader {
    margin: auto; background: rgba(255, 255, 255, 0); display: block; shape-rendering: auto;
  }
}