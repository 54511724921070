.widget {
	&__title {
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 0.01em;
		margin-bottom: 24px;
	}

	&__grid {
		display: flex;
		flex-wrap: wrap;
		row-gap: 15px;
		column-gap: 15px;
		padding-bottom: 15px;
	}

	&__card {
		width: 238px;
	}
}