@import '../../settings/main';

.filter-direction {

  width: 211px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  padding: 15px 0;
  border-radius: $border-radius--default;
  background-color: white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  z-index: 2;

  &__btn {
    @include border--default-gray;
    min-width: 211px;
    height: 100%;
    text-align: left;
    padding-left: 15px;
    padding-right: 30px;
    padding-bottom: 1px;
    background: white url("../../assets/svg/DownArrow.svg") no-repeat center right 15px;
  }

  &__background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .dropdown {

    &__item {
      display: flex;
      align-items: center;
      column-gap: 9px;
      padding: 0 13px;
      cursor: pointer;

      &:hover {
        border-left: 3px solid $color--yellow;
      }
    }
  }
}
