@import '../../settings/main';


.header {
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: $border--gray;
  width: 100%;
  z-index: 4;

  .popup-background {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    z-index: 3;
  }

  .score {
    padding-left: 10px;

    &__points {
      @include text--semi-bold-big;
    }

    &__level {
      @include text--regular-small;
      color: $color--dark-gray;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    width: 263px;
    height: 45px;
    padding: 0 0 0 15px;
  }

  .logo > img {
    height: 100%;
  }

  .navbar {
    display: flex;


    &__button-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100px;
      margin-left: 30px;
    }

    &__btn-phone, &__btn-mail {
      @include border--default-light-gray;
      height: 36px;
      width: 44px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }

    &__btn-phone {
      background: url('../../assets/svg/Phone.svg') no-repeat center;
    }

    &__btn-mail {
      background: url('../../assets/svg/Mail.svg') no-repeat center;
    }

    &__link_active {
      color: black;
    }

    &__link-block {
      display: flex;
      align-items: center;
      margin-left: 18px;

      .wow-color {
        color: $color--purple;
      }

      a {
        margin-left: 12px;
        color: $color--dark-gray;
        @include text--semi-bold-large;
      }

      .link_active {
        color: black;
      }
    }
  }

  .header-info {
    display: flex;
    height: 100%;
    align-items: center;

    &__debt {
      margin-right: 36px;
      height: 100%;
    }

    &__level {
      display: flex;
      width: 174px;
      height: 60px;
      flex: none;
      align-items: center;
      padding: 0 23px 0 30px;
      border-right: $border--gray;
      white-space: nowrap;
    }

    &__stickers {
      position: relative;
      height: 60px;
      z-index: 3;

      .button {
        display: flex;
        padding-right: 30px;
        border-right: $border--gray;
        cursor: pointer;
      }

      .stickers-dropdown {
        position: absolute;
        top: 65px;
        left: -100px;
      }
    }


    .popup {
      width: 74px;
      height: 60px;
      padding: 12px 16px;
      position: relative;
      text-align: right;
      background: url("../../assets/svg/Bell.svg") no-repeat center;
      border-right: $border--gray;
      cursor: pointer;

      &__score {
        display: inline-block;
        @include text--black-small;

        background: $color--yellow;
        border: 1px solid black;
        padding: 1px 4px;
        border-radius: 29px;
      }

      &__window {
        position: absolute;
        top: 55px;
        left: 0;
      }
    }

    &__identification {
      display: flex;
      align-items: center;
      height: 60px;
      min-width: 180px;
    }

    .header-basket {
      display: flex;
      width: 185px;
      height: 60px;
      justify-content: space-between;

      color: black;
      padding: 9px 16px 13px 0;
      background: url("../../assets/svg/Rectangle 5.svg") no-repeat right,
      url("../../assets/svg/Basket.svg") no-repeat left;

      &_active {
        background: url("../../assets/svg/Rectangle 5.svg") no-repeat right,
        url("../../assets/svg/basketLargeYellow.svg") no-repeat left;
      }

      &__number {
        width: 32px;
        height: 18px;

        align-self: center;
        @include text--black-small;
        text-align: center;

        margin-left: 35px;
        line-height: 13px;
        background-color: $color--yellow;
        border: 2px solid black;
        border-radius: 13px;
      }

      &__sum {
        display: flex;
        align-items: center;
        text-align: right;
        @include text--black-large;
        letter-spacing: $letter-spacing-small;
      }

      &__limit {
        @include text--regular-small;

        span {
          @include text--semi-bold-small;
        }
      }
    }
  }

  .identification {
    padding-left: 10px;

    &__person {
      @include text--semi-bold;
      font-size: 13px;
    }

    &__bank, &__city {
      @include text--regular-small;
      width: 100%;
      min-height: 14px;
      color: $color--dark-gray;
      .plug-container {
        position: relative;
        height: 10px;
        width: 100px;
      }
    }

    &__name {
      display: inline-flex;
      align-items: center;
      padding-left: 6.5px;
      .plug-container {
        position: relative;
        height: 12px;
        width: 100px;
      }
    }
  }

  @media screen and (max-width: $laptop) {
    .header-info__stickers {
      display: none;
    }
  }

}





