@import '../../settings/main';

.price-block {
  display: flex;
  flex-direction: column;
  line-height: 14px;

  &__percent {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: $color--red;
  }

  &__full {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-decoration: line-through;
    color: $color--dark-gray;
  }

  &__labels {
    display: flex;
    column-gap: 5px;
  }

  .price-card {
    @include text--semi-bold-large;
  }
}