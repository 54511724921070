@import '../../settings/main';

.sections-full {
  width: 100%;
  //height: calc(100vh - 120px);
  flex-direction: column;
  border-right: $border--gray;
  @include text--regular-large;
  overflow: auto;
  transition: all ease-in-out;

  .item {
    display: flex;
    align-items: center;
    height: 55px;
    border-bottom: $border--gray;

    &__active-arrow {
      margin: 0 13px 0 14.67px;

    }

    &__passive-arrow {
      margin: 0 14px 0 17px;
    }
  }

  .first-level {

    &__item {
      @include text--semi-bold-large;
      padding-left: 33px;
      border-bottom: $border--gray;
      cursor: pointer;
    }
  }
  .second-level {
    padding-left: 16px;
    border-bottom: $border--gray;

    &__item {
      border-bottom: $border--gray;
      border-left: $border--gray;
      cursor: pointer;
      padding-left: 33px;
      color: inherit;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .third-level {
    padding-left: 35px;
    border-bottom: $border--gray;
    border-left: $border--gray;

    span {
      padding-left: 20px;
    }

    &__item {
      border-left: $border--gray;
      border-bottom: $border--gray;
      color: inherit;
      cursor: pointer;

      &_active, &:hover {
        border-left: 3px solid $color--yellow;
        background-color: $color--super-light-gray;
        @include text--semi-bold-large;
        letter-spacing: 0.01em;
        &:last-child {
          border-bottom: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}