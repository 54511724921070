@import '../../settings/main';


.order-toolbar {
  display: flex;
  height: 84px;
  align-items: center;

  padding: 0 16px 0 23px;
  border-bottom: $border--light-gray;

  &__container {
    display: flex;
    height: 40px;
    width: 100%;
    column-gap: 20px;
    justify-content: space-between;

    .title-block {
      display: flex;
      align-items: center;
      column-gap: 15px;

      .bread-crumbs {
        white-space: nowrap;
        @include text--bold-xx-large;
        .back-link {
          color: $color--dark-gray;
          padding-left: 25px;
          background: url("../../assets/svg/ico/leftArrowLightBig.svg") no-repeat center left;
        }
      }

      .status {
        @include text--semi-bold-small;
        display: inline-block;

        padding: 2px 10px 3px 8px;
        color: $color--black-gray;
        background: $color--gray;
        border-radius: 19px;
      }
    }

    .buttons {
      display: flex;
      column-gap: 15px;

      .cancel-btn {
        @include border--default-light-gray;
        height: 100%;

        text-align: left;
        padding: 0 15px 0 41px;
        background: url("../../assets/svg/ico/cancel.svg") no-repeat center left 15px;
      }
    }
  }
}