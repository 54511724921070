@import '../../settings/main';

.image-slider {
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  .slider-photo {
    display: flex;
    align-items: center;
    justify-content: center;

    &__back-btn, &__next-btn {
      width: 35px;
      height: 35px;
      border: $border--light-gray;
      border-radius: 50%;
      cursor: pointer;
    }

    &__back-btn {
      background: url("../../assets/svg/backArrowLarge.svg") no-repeat center;
    }

    &__next-btn {
      background: url("../../assets/svg/nextArrowLarge.svg") no-repeat center;
    }
  }

  .carousel-photo {
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    column-gap: 11px;

    &__img {
      @include border--default-gray;
      width: 60px;
      height: 60px;
      cursor: pointer;
      object-fit: contain;
    }

    &__img_active {
      @include border--default-dark-gray;
      width: 60px;
      height: 60px;
    }
  }
}
