@import "../../settings/main";

.feedback {
	width: 100%;
	max-width: 420px;
	padding-top: 40px;
	margin: 0 auto;

	&__title {
		@include text--bold-xx-large;
		margin-bottom: 24px;
	}

	&__input {
		position: relative;
		border: 1px solid #ECECEC;
		border-radius: 4px;
		display: flex;
		min-height: 40px;
		width: 100%;
		min-width: 385px;
		align-items: center;
		background-color: white;
		padding: 0 12px;

		&_error {
			border-color: #f23b2f;
		}

		input, textarea {
			width: 100%;
		}

		textarea {
			resize: none;
			height: 170px;
			padding: 8px 0;
		}
	}

	&__error {
		color: #f23b2f;
		position: absolute;
		bottom: -20px;
	}
}