@import "variables";

@mixin text--regular {
  font-family: $font-family;
  font-weight: 400;
}

@mixin text--semi-bold {
  font-family: $font-family;
  font-weight: 600;
}

@mixin text--bold {
  font-family: $font-family;
  font-weight: 700;
}

@mixin text--black {
  font-family: $font-family;
  font-weight: 900;
}

@mixin text--regular-xx-large {
  @include text--regular;
  font-size: $font-size--xx-large;
  line-height: $line-height--super-large;
  letter-spacing: $letter-spacing-small;
}

@mixin text--regular-large {
  @include text--regular;
  font-size: $font-size--large;
  line-height: $line-height--large;
  letter-spacing: $letter-spacing-small;
}

@mixin text--regular-big {
  @include text--regular;
  font-size: $font-size--big;
  line-height: $line-height--big;
  letter-spacing: $letter-spacing-small;
}

@mixin text--regular-small {
  @include text--regular;
  font-size: $font-size--small;
  line-height: $line-height--small;
  letter-spacing: $letter-spacing-small;
}

@mixin text--regular-x-small {
  @include text--regular;
  font-size: $font-size--x-small;
  line-height: $line-height--x-small;
  letter-spacing: $letter-spacing-small;
}

@mixin text--semi-bold-large {
  @include text--semi-bold;
  font-size: $font-size--large;
  line-height: $line-height--large;
  letter-spacing: $letter-spacing-small;
}

@mixin text--semi-bold-big {
  @include text--semi-bold;
  font-size: $font-size--big;
  line-height: $line-height--big;
  letter-spacing: $letter-spacing-small;
}

@mixin text--semi-bold-small {
  @include text--semi-bold;
  font-size: $font-size--small;
  line-height: $line-height--small;
  letter-spacing: $letter-spacing-small;
}

@mixin text--bold-xx-large {
  @include text--bold;
  font-size: $font-size--xx-large;
  line-height: $line-height--super-large;
  letter-spacing: $letter-spacing-small;
}

@mixin text--bold-large {
  @include text--bold;
  font-size: $font-size--large;
  line-height: $line-height--large;
  letter-spacing: $letter-spacing-small;
}

@mixin text--bold-big {
  @include text--bold;
  font-size: $font-size--big;
  line-height: $line-height--big;
  letter-spacing: $letter-spacing-small;
}

@mixin text--bold-small {
  @include text--bold;
  font-size: $font-size--small;
  line-height: $line-height--small;
  letter-spacing: $letter-spacing-small;
}


@mixin text--black-large {
  @include text--black;
  font-size: $font-size--large;
  line-height: $line-height--large;
  letter-spacing: $letter-spacing-small;
}

@mixin text--black-big {
  @include text--black;
  font-size: $font-size--big;
  line-height: $line-height--big;
  letter-spacing: $letter-spacing-small;
}

@mixin text--black-small {
  @include text--black;
  font-size: $font-size--small;
  line-height: $line-height--small;
  letter-spacing: $letter-spacing-small;
}


//BORDER
@mixin border--default-light-gray {
  border: $border--light-gray;
  border-radius: $border-radius--default;
}

@mixin border--default-gray {
  border: $border--gray;
  border-radius: $border-radius--default;
}

@mixin border--default-dark-gray {
  border: $border--dark-gray;
  border-radius: $border-radius--default;
}

@mixin border--default-yellow {
  border: $border--dark-yellow;
  border-radius: $border-radius--default;
}
@mixin button--default-yellow {
  border: $border--dark-yellow;
  background: $color--yellow;
  border-radius: $border-radius--default;
}
