@import '../../settings/main';

.order-filter {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  border-radius: $border-radius--default;

  &__container {
    height: 100vh;
    width: 370px;
    padding-top: 11px;
    background: white;
    padding-bottom: 74px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  }

  &__main-block {
    width: 370px;
    height: 100%;
    overflow-y: auto;
  }

  &__title {
    display: block;
    background: url("../../assets/svg/ico/filter.svg") no-repeat center left;
    padding-left: 25px;
    margin-left: 15px;
    margin-bottom: 4px;


    @include text--semi-bold-large;
    letter-spacing: $letter-spacing-small;
  }

  &__item {
    padding: 10px 15px 15px 15px;
    border-bottom: $border--light-gray;

    .title {
      display: block;
      @include text--semi-bold-small;
      letter-spacing: $letter-spacing-big;
      margin-bottom: 11px;
    }

    .select-button {
      display: block;
      height: 40px;
      width: 100%;
      text-align: left;
      padding-left: 15px;

      @include border--default-light-gray;
      background: url("../../assets/svg/DownArrow.svg") no-repeat right 15px center;
      background-size: 8.67px 3.99px;

      &__text {
        color: $color--dark-gray;
      }

      &__text_active {
        color: black;
      }
    }

    .date-container {
      display: flex;
      column-gap: 16px;
      .date-picker {
        width: 162px;
        height: 40px;
        display: flex;
        align-items: center;

        padding-left: 15px;
        @include text--regular-small;
        color: $color--dark-gray;
        @include border--default-light-gray;
        background: url("../../assets/svg/ico/date.svg") no-repeat center right 15.5px;
      }
    }
  }

  &__btn-block {
    position: absolute;
    width: 370px;
    bottom: 0;
    height: 74px;
    border-top: $border--light-gray;
    padding: 15px 15px 20px 15px;
    .filter-button-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 38px;

      &__btn-apply {
        width: 200px;
        height: 100%;
        @include button--default-yellow;
        box-shadow: 0 1px 2px rgba(117, 91, 0, 0.2);
      }

      &__btn-reset {
        width: 125px;
        height: 100%;
        @include border--default-light-gray;
      }
    }
  }

  &__toggle-block {
    padding: 20px 19px;
    .item {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
}

