@import '../../settings/main';

.select {
  height: 100%;
  &__trigger {
    height: 100%;
  }

  .dropdown {
    position: absolute;
    top: 55px;
  }
}