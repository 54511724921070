@import '../../settings/main';

.labels {
	display: flex;
	align-items: center;
	flex-direction: column;

	&__item {
		margin-bottom: 5px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}