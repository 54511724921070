@import '../../settings/main';


.orders-list {
  width: 100%;

  .inscription {
    @include text--semi-bold-big;
    margin-top: 15px;
    margin-left: 16px;
  }

  &__checkbox {
    display: flex;
    align-items: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      tr:hover {
        background-color: $color--super-light-gray;
      }
    }

    td, th {
      &:first-child {
        padding-left: 0;
      }

      &:nth-child(2) {
        padding-left: 7px;
      }

      &:last-child {
        padding-right: 16px;
        width: 65px;
      }
    }

    th {
      @include text--regular-small;
      height: 32px;

      text-align: center;
      padding-left: 30px;
      border-bottom: $border--gray;
      color: $color--black-gray;
    }

    td {
      height: 46px;

      border-bottom: $border--gray;
      padding-left: 30px;
      cursor: pointer;

      &:nth-child(7) {
        text-align: right;
      }

      &:nth-child(8) {
        text-align: center;
      }

      &:last-child {
        padding-right: 16px;
        text-align: center;
        width: 65px;
      }

      .status {
        height: 100%;
        width: 3px;
        border-radius: 0 3px 3px 0;
        margin: 0 !important;
      }

      .print-btn {
        width: 65px;
        height: 38px;
      }
    }
  }
}