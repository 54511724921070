@import '../../settings/main';

.forgot-password {
	background-color: #FFF;

	&__input {
		position: relative;
		border: 1px solid #ECECEC;
		border-radius: 4px;
		display: flex;
		min-height: 40px;
		width: 100%;
		min-width: 385px;
		align-items: center;
		background-color: white;
		padding: 0 12px;
		margin-bottom: 24px;

		&_error {
			border-color: #f23b2f;
		}

		input {
			width: 100%;
		}
	}

	&__error {
		color: #f23b2f;
		position: absolute;
		bottom: -20px;
	}

	&__btn {
		@include border--default-yellow;
		@include text--bold-big;
		background-color: $color--yellow;
		height: 30px;
		width: 100%;
	}
}