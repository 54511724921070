@import '../../../settings/main';

.basket-toolbar {
  display: flex;
  flex: none;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  align-items: center;

  border-bottom: $border--gray;
  background-color: $color--super-light-gray;
  justify-content: space-between;

  &__left-col {
    display: flex;
    column-gap: 10px;
    padding-right: 15px;

    .add-barcode-btn {
      width: 244px;
      flex: none;
      @include border--default-light-gray;
      padding: 0 39px 0 15px;
      color: $color--dark-gray;
      background: white url("../../../assets/svg/ico/basket/add.svg") no-repeat center right 15px;
    }

    .debt-btn {
      margin-left: 5px;
    }

  }

  &__right-col {
    display: flex;
    column-gap: 15px;
    .order-details-modal {
       z-index: 1;
    }
  }
}
