@import "../../../settings/main";

.modal-default {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgb(0, 0, 0, 0.2);

  &__window {
    display: flex;

  }

  &__content {
    display: flex;
    border-radius: 15px;
  }
}
