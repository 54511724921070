@import '../../settings/main';

.stock-info {
  @include text--regular-small;
  width: 550px;
  height: 610px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
  background: white;

  &__item {
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-top: 20px;
    border-bottom: $border--light-gray;

    &:last-child {
      border-bottom: none;
    }
  }

  &__status_full, &__status_normal, &__status_little, &__status_few {
    background-size: 39.2px 7px;
    padding-left: 66px;
    width: 151px;
    flex: none;
  }

  &__status_full {
    background: url("../../assets/svg/ico/stockInfo/full.svg") no-repeat center left;
  }

  &__status_normal {
    background: url("../../assets/svg/ico/stockInfo/normal.svg") no-repeat center left;
  }

  &__status_little {
    background: url("../../assets/svg/ico/stockInfo/little.svg") no-repeat center left;
  }

  &__status_few {
    background: url("../../assets/svg/ico/stockInfo/few.svg") no-repeat center left;
  }

  .info-list {

    &__options-block {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }

    &__option-item {
      display: flex;
      margin-bottom: 15px;
    }

    &__ico {
      width: 15px;
      height: 15px;
      margin-top: 2px;
      background: url('../../assets/svg/ico/stockInfo/Vector.svg') no-repeat center,
      url('../../assets/svg/ico/stockInfo/ok.svg') center/15px 15px no-repeat;
    }


    &__options {
      @include text--regular-small;
      width: 260px;
      padding-left: 9px;
    }

    &__title {
      @include text--semi-bold-small;
    }
  }
}

