@import "../../settings/main";


.basket-download {
  display: flex;
  column-gap: 9px;
  button {
    @include border--default-light-gray;
    @include text--semi-bold-small;
    width: 65px;
    height: 26px;

    text-align: left;
  }
  &__excel {
    background: url("../../assets/svg/ico/basket/excel.svg") no-repeat center left 10px;
    padding-left: 28px;
  }
  &__email {
    background: url("../../assets/svg/ico/basket/emailSmall.svg") no-repeat center left 10px;
    padding-left: 25px;
  }
}