$show-element: show-element 0.23s;

@keyframes show-element {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

$background-opacity: background-opacity 0.43s;

@keyframes background-opacity {
  0% {
    background: rgb(0, 0, 0, 0);
  }
  100% {
    background: rgb(0, 0, 0, 0.2);
  }
}

$drop-down: drop-down 0.4s;

@keyframes drop-down {
  0% {
    transform: translateY(-200px);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

.popup-content {
  -webkit-animation: anvil 0.3s forwards;
}

@keyframes anvil {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}