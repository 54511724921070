@import '../../settings/main';

.catalog {
  &__modal-background {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0, 0, 0, 0.2);
    z-index: 2;
  }

  &__modal-window {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 3;

    background: rgb(0, 0, 0, 0.2);

    .content-container {
      display: flex;
      z-index: 4;
    }
  }

  &__toolbar {
    position: relative;
    z-index: 2;
  }
}

.catalog-wrapper {
  height: 100%;
  display: flex;
  //overflow-y: hidden;
  position: relative;

  &__filter {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

.catalog-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__content {
    width: 100%;
    position: relative;
    flex: 1 1 auto;

    .empty-response {
      @include text--regular-big;
      color: $color--dark-gray;
      margin: 20px;
    }
  }

  &__grid {
    display: flex;
    padding: 15px 5px 15px 15px;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
  }

  &__bottom-tools {
  }
}
