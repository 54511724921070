/* COLOR */
$color--white-gray: #FAFAFA;
$color--super-light-gray: #F7F7F7;
$color--light-gray: #E5E5E5;
$color--gray: #ECECEC;
$color--dark-gray: #A5A5A5;
$color--black-gray: #808080;

$color--light-yellow: rgba(254, 219, 67, 0.6);
$color--yellow: #FEDB43;
$color--dark-yellow: #F2CB3F;

$color--purple: #7629D9;

$color--scarlet: #E15950;

$color--light-red: #FF574D;
$color--vino-lite: rgba(255, 87, 77, 0.3);

$color--red: #F23B2F;

$color--light-green: rgba(140, 215, 139, 0.4);
$color--green: #2CCF29;

/* FONT-SIZE */
$font-size--xx-large: 24px;
$font-size--x-large: 20px;
$font-size--large: 16px;
$font-size--big: 14px;
$font-size--small: 12px;
$font-size--x-small: 10px;
/* LINE_HEIGHT */
$line-height--super-large: 36px;
$line-height--large: 20px;
$line-height--big: 18px;
$line-height--small: 15px;
$line-height--x-small: 13px;
/* LETTER-SPACING */
$letter-spacing-large: 0.05em;
$letter-spacing-big: 0.03em;
$letter-spacing-small: 0.01em;
/* FONT-FAMILY */
$font-family: 'Source Sans Pro', sans-serif;
/* BORDER-RADIUS */
$border-radius--default: 4px;
/* BORDER */
$border--light-gray: 1px solid $color--light-gray;
$border--gray: 1px solid $color--gray;
$border--dark-gray: 1px solid $color--dark-gray;
$border--dark-yellow: 1px solid $color--dark-yellow;
$border--red: 1px solid $color--red;
$border--scarlet: 1px solid $color--scarlet;
$border--green: 1px solid $color--green;
/* HEIGHT */
$height--header: 60px;
$height--tool-bar: 60px;
