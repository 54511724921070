@import '../../settings/main';

.app-wrapper {
  @media screen and (max-width: 767.98px) {
    min-width: auto;
  }
}

.login {
  margin-top: 20px;

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 20%;
    margin: 0 auto;

    @media screen and (max-width: 767.98px) {
      width: 70%;
    }

    @media screen and (max-width: 575.98px) {
      width: 90%;
    }

    .input {
      @include border--default-light-gray;
      height: 30px;
      width: 100%;
      padding: 0 10px;
      &_error {
        border-color: $color--red;
      }
    }

    .server-error {
      color: $color--red;
    }

    label {
      @include text--semi-bold-big;
    }

    .captcha {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      &__input {
        margin: 0;
      }

      &__label {
        margin-right: 10px;
      }
    }

    .remember-me {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .submit {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 15px;

      &__btn {
        @include border--default-yellow;
        @include text--bold-big;
        background-color: $color--yellow;
        height: 30px;
        width: 100%;
      }
    }
  }
}