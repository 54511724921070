@import '../../settings/main';

.photo-hover {
  display: flex;
  width: 400px;
  height: 300px;
  align-items: center;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius--default;
  z-index: 1;


  &__diamond {
    width: 25px;
    height: 25px;
    border-radius: $border-radius--default;
    vertical-align: middle;
    transform: rotate(45deg);
    background: white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    z-index: 2;
    margin-left: -8px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: -17px;
    border-radius: $border-radius--default;
    background: white;
    overflow: hidden;
    z-index: 3;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}