@import '../../settings/main';

.news {
  width: 1280px;
  margin: 0 auto;
  min-height: 100%;

  background: white;
  box-shadow: 0 74px 100px rgba(0, 0, 0, 0.2);
  padding: 15px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
  }

  &__content {
    padding-bottom: 30px;
  }

  &__grid {
    width: 100%;
    padding: 0;
    align-items: center;
    justify-content: space-around;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-column-gap: 1%;
    grid-row-gap: 20px;

    li {
      border: 1px solid #eee;
      margin-bottom: -1px;
      float: left;
      list-style-type: none;
    }
  }
}

.news-item {
  position: relative;
  padding: 20px;

  &:hover {
    box-shadow: 0 0 8px rgba(0,0,0,0.55);
    z-index: 10;
  }

  &__image {
    height: 100%;
    text-align: center;
    margin: 0 0 12px;
    position: relative;
    transition: 0.5s all ease;
    min-height: 258px;

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }
  }

  &__text-link {
    display: -webkit-box;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    color: #0a0a0a;
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: 0.5s all ease;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    text-align: left;
    height: 80px;
    overflow: hidden;
    transition: 0.5s all ease;
  }

  &__date {
    font-size: 15px;
  }
}