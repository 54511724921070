@import '../../settings/main';

.sections-hidden {
  display: flex;
  position: relative;
  flex: none;
  height: 100%;

  &__container {
    width: 55px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    border-bottom: $border--gray;
    cursor: pointer;
    &:hover {
      background-color: $color--super-light-gray;
      border-left: 3px solid $color--yellow ;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
  .catalog-nav-container {
    width: 370px;
    height: 100%;
    position: relative;
    border-left: $border--light-gray;
    background: white;
    box-shadow: 15px 5px 20px rgba(0, 0, 0, 0.05);
  }

  .catalog-nav-enter {
    animation: show-element 0.3s forwards;
  }
  .catalog-nav-active {
    opacity: 1;
  }
  .catalog-nav-exit {
    opacity: 1;
  }
  .catalog-nav-exit-active {
    animation: show-element 0.3s reverse;
  }

}
