@import '../../settings/main';

.forgot-page {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	&__form {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		width: 20%;
		margin: 0 auto;

		.input {
			@include border--default-light-gray;
			height: 30px;
			width: 100%;
			padding: 0 10px;
			&_error {
				border-color: $color--red;
			}
		}

		label {
			@include text--semi-bold-big;
		}

		.submit {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			row-gap: 15px;

			&__btn {
				@include border--default-yellow;
				@include text--bold-big;
				background-color: $color--yellow;
				height: 30px;
				width: 100%;
			}
		}
	}
}