@import '../../settings/main.scss';

//.select {
//  width: 100%;
//  height: 100%;
//  position: relative;
//
//  .select-button {
//    @include border--default-gray;
//    width: 100%;
//    height: 100%;
//    text-align: left !important;
//    padding-left: 15px;
//    background: white;
//
//    &_down-arrow {
//      background: url("../../assets/svg/DownArrow.svg") no-repeat center right 15px;
//    }
//
//    &_up-arrow {
//      background: url("../../assets/svg/UpArrow.svg") no-repeat center right 15px;
//    }
//  }
//
//  .select-options {
//    position: absolute;
//    width: 100%;
//
//    &__container {
//      display: flex;
//      flex-direction: column;
//      justify-content: space-between;
//      row-gap: 10px;
//      padding: 15px 0;
//      border-radius: $border-radius--default;
//      background-color: white;
//      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
//    }
//
//    &__item {
//      display: flex;
//      align-items: center;
//      column-gap: 9px;
//      padding: 0 13px;
//      cursor: pointer;
//
//      &:hover {
//        border-left: 3px solid $color--yellow;
//      }
//
//    }
//  }
//}

.select-button {
  @include border--default-gray;
  width: 100%;
  height: 100%;
  text-align: left !important;
  padding-left: 15px;
  background: white;

  &_down-arrow {
    background: url("../../assets/svg/DownArrow.svg") no-repeat center right 15px;
  }

  &_up-arrow {
    background: url("../../assets/svg/UpArrow.svg") no-repeat center right 15px;
  }
}

.popup-content {
  padding: 5px 0;

  .select-options {
    width: 100%;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 10px;
      padding: 15px 0;
      border-radius: $border-radius--default;
      background-color: white;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    }

    &__item {
      display: flex;
      align-items: center;
      column-gap: 9px;
      padding: 0 13px;
      cursor: pointer;

      &:hover {
        border-left: 3px solid $color--yellow;
      }

    }
  }
}
