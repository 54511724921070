@import '../../settings/main';

.upload-excel-button {
  @include text--semi-bold-big;
  width: 218px;
  height: 40px;
  flex: none;

  @include border--default-light-gray;
  text-align: left;
  background: white url("../../assets/svg/Excel.svg") no-repeat center left 15px;
  padding-left: 47px;
}