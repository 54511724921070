.banner {
  position: relative;
  background: #FFF3BE;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    margin-left: 16px;

    span {
      font-weight: 400;
      margin-left: 8px;
    }
  }

  &__link {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #FF7216;
    margin-left: 20px;
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    opacity: 0.5;
  }
}