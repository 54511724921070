@import "../../../settings/main";

.modal-right-side {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgb(0, 0, 0, 0.2);

  &__window {
    display: flex;
  }

  &__content {
    display: flex;
    position: relative;
  }
}

.modal-right-side-enter {
  animation: $background-opacity forwards;

  .modal-right-side__window {
    animation: slide-left .4s forwards;
  }
}

.modal-right-side-exit-active {
  animation: $background-opacity reverse;

  .modal-right-side__window {
    animation: slide-left .43s reverse;
    opacity: 1;
  }

}

@keyframes slide-left {
  0% {
    transform: translateX(370px);
  }

  50% {
    transform: translateX(-40px);
  }

  100% {
    transform: translateX(0);
  }
}








