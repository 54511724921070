@import '../../settings/main';


.orders-toolbar {
  display: flex;
  height: 84px;
  align-items: center;

  padding: 0 16px 0 30px;
  border-bottom: $border--light-gray;

  &__container {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: space-between;

    .left-col {
      display: flex;

      .title {
        display: flex;
        column-gap: 15px;

        &__name {
          @include text--bold-xx-large;
        }

        &__count {
          @include text--regular-xx-large;
          color: #C4C4C4;
        }

      }

      .select-count {
        @include text--semi-bold-big;
        margin-left: 30px;
        line-height: 40px;
      }

      .button-block {
        display: flex;
        column-gap: 15px;
        margin-left: 25px;


      }

    }

    .right-col {
      display: flex;
      column-gap: 15px;
    }
  }
}
