@import '../../settings/main';

.products-list {
  width: 100%;
  outline: none;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    text-align: center;
  }

  td {
    text-align: left;

    &:nth-child(10) {
      text-align: center;
    }

    &:nth-child(6), &:nth-child(7) {
      text-align: center;
    }
  }

  td, th {
    padding-left: 31px;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 12px;
    }

    &:nth-child(7), &:nth-child(10) {
      padding-left: 8px;
    }

    &:nth-child(3) {
      white-space: nowrap;
    }

    &:nth-child(11) {
      text-align: center;
      width: 96px;
    }

    &:nth-child(12) {
      text-align: center;
      width: 65px;
    }

  }

  &__product-photo {
    position: relative;
  }

  &__basket-head {
    width: 65px;
    text-align: center;
  }
  
  &__price {
    &>div {
      text-align: right;
    }
  }

  &__quantity-selector {
    width: 96px;
  }

  &__btn {
    width: 65px;
  }


  &__marker-ico {
    padding: 0 38px;
  }

  @media screen and (max-width: $laptop) {

    th:nth-child(3), td:nth-child(3) {
      display: none;
    }
  }

  &__photo {
    img {
      object-fit: contain;
    }
  }

  &__link {
    color: inherit;
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  .table {
    td {
      cursor: default;
    }
  }
}

