@import '../../../settings/main';

.basket-order-details {
  width: 378px;
  height: 100%;

  background: $color--white-gray;
  border-left: $border--light-gray;

  overflow-y: auto;
  .wow-color {
    color: $color--purple;
  }

  &__container {
    padding: 21px 25px;

    .title {
      @include text--bold-xx-large;
      letter-spacing: 0.02em;
    }

    .table {
      width: 100%;
      margin-top: 19px;

      th, td {
        height: 40px;
        border-bottom: $border--light-gray;
        text-align: left;

        &:nth-child(2), &:nth-child(3) {
          text-align: right;
        }
      }

      th {
        @include text--regular-big;
        color: $color--dark-gray;
      }
    }
    .flex-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      &__row {
        height: 40px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: $border--light-gray;
        &:last-child {
          border: none;
        }
      }
    }
    .bonus-block {
      @include border--default-light-gray;
      margin-top: 15px;
      &__toggle {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 10px 15px;
        .toggle {
          display: inherit;
          align-items: center;
          column-gap: 14px;
        }
      }
      &__input {
        height: 40px;

        padding: 10px 15px;
        color: $color--black-gray;
        border-top: $border--light-gray;
        background: white;
      }
    }
    .btn-container {
      margin-top: 11px;
    }
  }
}