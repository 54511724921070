@import '../../settings/main';

.product-card {
  @include border--default-gray;
  @include text--regular-small;
  position: relative;
  flex-direction: column;
  width: 227px;
  height: 407px;
  cursor: pointer;
  color: inherit;

  &__container {
    display: flex;
    flex-direction: column;
  }
  &__head {
    padding-top: 9px;
    border-radius: 4px 4px 0 0;
    height: 190px;
  }

  &__content {
    padding: 0 15px 15px 15px;
  }

  &__photo {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    overflow: hidden;
    max-width: 100%;
    img {
      max-width:100%;
      max-height:100%;
      object-fit: contain;
    }
  }

  &__title {
    width: 195px;
    height: 51px;
  }

  &__id {
    display: flex;
    width: 195px;
    justify-content: space-between;
    color: $color--black-gray;
    margin-top: 10px;
  }

  .buttons-container {
    display: flex;
    flex: none;
    width: 100%;
    column-gap: 14px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    &__quantity-btn {
      width: 106px;
    }

    &__basket-btn {
      height: 38px;
      width: 77px;
    }
  }

  &__markers-container {
    position: absolute;
    padding: 0 10px;
  }

  &__favorite {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &:hover {
    & {
      border-color: white;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      transition: all 0.3s;
    }

    //.product-card__head {
    //  background: url("../../assets/svg/content/ChildrenSafe.svg")  0 0/cover no-repeat;
    //}
    //
    //.product-card__photo {
    //  display: none;
    //}
  }
}

.count-block {
  display: flex;
  justify-content: space-between;

  &__price-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .price-block {
      align-self: flex-end;
    }
  }

  .right-coll {
    display: flex;

    &__price {
      @include text--regular-large;
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    &__discount {
      display: flex;
      @include text--regular-x-small;
    }

    &__percent-discount {
      font-weight: 700;
      color: $color--red;
    }

    &__full-price {
      text-decoration: line-through;
      color: $color--dark-gray;
      margin-left: 5px;
    }

    &__order-block {
      margin-left: 10px;
    }

    &__title {
      @include text--regular-x-small;
      color: $color--black-gray
    }
    
    &__amount {
      text-align: center;
    }
  }
}

.count-block {
  margin-top: 20px;

  &__title {
    @include text--regular-x-small;
    color: $color--black-gray
  }
}


