@import '../../settings/main';

.download-select {
  height: 100%;

  .download-btn {
    @include border--default-gray;
    width: 76px;
    height: 100%;
    background: url("../../assets/svg/Excel.svg") no-repeat center left 15px,
    url("../../assets/svg/DownArrow.svg") no-repeat center right 15px;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &__excel-ico {
      padding-right: 15px;
    }
  }

  .dropdown {
    position: absolute;
    width: 220px;
    z-index: 2;

    padding: 16px 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius--default;
    background: white;
  }

  &__in, &__out {
    span {
      display: block;
      color: $color--dark-gray;
      margin-bottom: 10px;
    }
    button {
      @include text--semi-bold-big;
      padding-left: 22px;
    }
  }
  &__in {
    button {
      background: url('../../assets/svg/ico/download/in.svg') no-repeat left center;
    }
  }

  &__out {
    margin-top: 11px;
    button {background: url('../../assets/svg/ico/download/out.svg') no-repeat left center;}
  }
}