@import '../../settings/main';


.order-list {
  width: 100%;
  outline: none;
  border-spacing: 0;
  border-collapse: collapse;

  .article {
    white-space: nowrap;
  }

  th, td {
    padding: 0 10px;
    &:first-child, &:first-child {
      padding-left: 15px;
    }
    &:last-child, &:last-child {
      padding-right: 15px;
    }
  }

  th {
    @include text--regular-small;
    text-align: center;
    border-bottom: $border--gray;
    height: 32px;
    color: $color--black-gray;
    white-space: nowrap;
  }

  td {
    align-items: center;
    border-bottom: $border--gray;
    height: 46px;
    cursor: pointer;
  }

  &__basket-head {
    width: 65px;
    text-align: center;
  }

  &__tm,
  &__location,
  &__stock,
  &__quantity {
    text-align: center;
  }
  
  &__price,
  &__total-price {
    text-align: right;
  }

  .photo {
    position: relative;

    &__full-photo {
      position: absolute;
      left: 32px;
      top: -130px;
    }
  }
}