@import '../../settings/main';

.side-bar {
  min-width: 55px;
  flex: none;

  &__full {
    flex: none;
    width: 445px;
  }

  &__hidden {
    //height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
  }
}