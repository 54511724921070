@import '../../settings/main';

.filter-note {
	display: inline-block;
	position: absolute;
	top: 30px;
	right: 93%;
	min-width: max-content;
	color: #000;
	padding: 0 15px;
	line-height: 32px;
	white-space: nowrap;
	z-index: 30;
	margin-right: 16px;
	border: 1px solid $color--yellow;
	background: $color--yellow;
	box-shadow: 0 1px 9px rgb(0 0 0 / 21%);
	border-radius: 10px;
	text-decoration: underline;
	cursor: pointer;
	transform: translateY(-50%);

	&:after,
	&:before {
		left: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:before {
		border-color: rgba(255, 220, 45, 0);
		border-left-color: #e1e1e1;
		border-width: 9px;
		margin-top: -9px;
	}

	&.filter-note:after {
		border-color: rgba(255, 255, 255, 0);
		border-left-color: #ffdc2d;
		border-width: 7px;
		margin-top: -7px;
	}
}