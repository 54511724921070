@import '../../../settings/main';


.basket-list {
  width: 100%;
  outline: none;
  border-spacing: 0;
  border-collapse: collapse;

  &__sort-th {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      margin-left: 5px;
    }
  }

  &__vat-price {
    white-space: nowrap;
  }

  &__total-price {
    @include text--bold-big;
    white-space: nowrap;
  }
  
  &__tm {
    text-align: center;
  }

  &__quantity-selector {
      width: 96px;
  }

  th, td {
    padding: 0 10px;
  }

  th:first-child, td:first-child {
    padding-left: 15px;
  }

  th:last-child, td:last-child {
    padding-right: 15px;
  }

  th {
    @include text--regular-small;
    text-align: center;
    border-bottom: $border--gray;
    height: 32px;
    color: $color--black-gray;
    white-space: nowrap;

    &:first-child, &:first-child {
      padding-left: 15px;
    }

    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {

    }
  }

  td {
    align-items: center;
    border-bottom: $border--gray;
    height: 46px;
    cursor: pointer;

    .delete-btn {
      width: 65px;
    }

    &:nth-child(8), &:nth-child(9), &:nth-child(10) {
      text-align: center;
    }

    &:nth-child(11), &:nth-child(12) {
      text-align: right;
    }
  }

  tr:last-child td {
    border: none;
  }

  &__basket-head {
    width: 65px;
    text-align: center;
  }

  .product-photo {
    position: relative;

    &__full-photo {
      position: absolute;
      left: 32px;
      top: -130px;
    }
  }
}