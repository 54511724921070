@import '../../settings/main';

.amount-button {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;

  span {
    padding-right: 7px;
  }
  &__plus {
    width: 10px;
    height: 10px;
    background: url("../../assets/svg/Plus.svg") no-repeat center;
  }
  &__minus {
    width: 10px;
    height: 10px;
    background: url("../../assets/svg/Minus.svg") no-repeat center;
  }

  &__btn {
    @include border--default-light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 100%;
    background: white;
  }
  &__select {
    position: absolute;
    top: 40px;
    text-align: left;
    z-index: 1;
  }
}

.toggle-input {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 25px;
  height: 13px;
  vertical-align: top;
  border: 1.5px solid $color--light-gray;
  border-radius: 6.5px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 0;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: white;

    border: 1.5px solid $color--dark-gray;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    top: -1.5px;
    left: -2px;

    transform: translateX(0);
  }

  &:checked::after {
    transform: translateX(100%);
    background-color: $color--yellow;
    border: 1.5px solid black;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  &:checked {
    background: white;
  }
}

.excel-download {
  @include text--semi-bold-big;
  width: 218px;
  height: 40px;
  flex: none;

  @include border--default-light-gray;
  text-align: left;
  background: white url("../../assets/svg/Excel.svg") no-repeat center left 15px;
  padding-left: 47px;
}

