@import '../../settings/main';

.table {
  width: 100%;
  outline: none;
  border-spacing: 0;
  border-collapse: collapse;

  tbody {
    tr {
      &:hover {
        background: $color--white-gray;
      }
    }
  }

  tfoot {
    tr {
      border-top: $border--gray;
    }
  }

  th {
    @include text--regular-small;
    border-bottom: $border--gray;
    height: 32px;
    color: $color--black-gray;
    white-space: nowrap;
  }

  td {
    align-items: center;
    border-bottom: $border--gray;
    height: 46px;
    cursor: pointer;
  }
}

