@import '../../settings/main';


.conform-button-group {
  button {
    height: 38px;
  }

  .accept {
    width: 178px;

    background: $color--light-yellow;
    border: $border--dark-yellow;
    border-radius: $border-radius--default 0 0 $border-radius--default;
    &_active {
      background: $color--light-green;
      border: $border--green;
    }
    &_passive {
      @include border--default-light-gray;
      background: white;
    }
  }

  .reject {
    @include border--default-light-gray;
    width: 173px;
    border-radius: 0 $border-radius--default $border-radius--default 0;
    &_active {
      background: $color--vino-lite;
      border: $border--scarlet;
    }
  }
}