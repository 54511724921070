@import '../../../settings/main';

.basket-item {
  border: $border--light-gray;
  border-radius: 10px;

  &_active {
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.1);
    border: none;
    transition: box-shadow 0.2s;
  }

  &__container {
    display: flex;
    height: 100%;

    .item-info {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .item-head {
        flex: none;
        padding: 25px 30px 0 20px;

        &__title {
          @include text--bold-xx-large;
          padding-left: 10px;
        }

        &__info {
          padding-left: 10px;
        }

        &__email {
          margin-top: 10px;
          @include text--regular-big;
          color: $color--dark-gray;
        }

        .add-note {
          @include text--semi-bold-big;
          background: url("../../../assets/svg/ico/basket/note.svg") no-repeat center left;
          margin-top: 19px;
          padding-left: 20px;

        }
      }

      .count-table {
        display: table;
        text-align: left;
        margin-top: 2px;
        padding: 30px 0;

        th {
          @include text--regular-large;
          color: $color--dark-gray;
        }

        td {
          padding-top: 13px;
          @include text--regular-big;
          font-size: 25px;
          height: 38px;
        }

        &__count {
          width: 110px;
        }

        &__price {
          width: 200px;
        }

        &__limit {
          width: 185px;
        }

        &__limit-bar {
          height: 4px;
        }
      }
    }

    .basket-item__tools-block {
      flex: none;
      padding: 28px 28px 28px 0;

      .tools-block {
        border-left: $border--light-gray;
        padding-left: 30px;

        .download {
          display: flex;
          column-gap: 23px;
          margin-top: 10px;

          .title {
            @include text--regular-small;
            color: $color--dark-gray;
            margin-bottom: 5px;
          }

          .download__left-col {

          }

          .download__right-col {

          }
        }
      }
    }
  }
}


