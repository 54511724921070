@import 'settings/main';

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  @include text--regular-big;
  height: 100vh;
  width: 100vw;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  background: none;
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a:focus, a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: $color--dark-gray;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $color--dark-gray;
}
/*-----------------------------------*/

.ml-3 {
  margin-left: 3px;
}

.ml-4 {
  margin-left: 4px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

/*-----------------------------------*/

.app-wrapper {
  position: relative;
  height: 100vh;
  min-width: 1280px;
}

/*----------DEFAULT ANIMATION---------*/
.show-el-enter {
  animation: $show-element forwards;
}

.show-el-active {
  opacity: 1;
}

.show-el-exit {
  opacity: 1;
}

.show-el-exit-active {
  animation: $show-element reverse;
}
/*-----------------------------------*/

.drop-down-enter {
  animation: $drop-down forwards;
}

.drop-down-exit-active {
  animation: $drop-down reverse;
}

