@import "../../settings/main";

.order-form {
  width: 420px;
  height: 739px;
  padding: 20px 0;

  &__title {
    @include text--bold-xx-large;
    padding-left: 25px;
  }

  &__table {
    display: flex;
    margin-top: 20px;

    .left-col, .right-col {
      border-top: $border--gray;
      width: 100%;

      div {
        display: flex;
        align-items: center;
        height: 30px;
        border-bottom: $border--gray;
      }
    }

    .left-col {
      color: $color--black-gray;
      div {
        padding-left: 25px;
      }
    }

    .right-col {
      div {
        padding: 0 20px;
      }

    }
  }

  .form-block {
    padding: 25px 25px 0 25px;

    &__item {
      padding-bottom: 20px;

      .title {
        margin-bottom: 11px;
      }
    }
    .toggle {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
  .button-block {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;

    .conform-order {
      @include button--default-yellow;
      @include text--semi-bold-big;
      height: 38px;
    }
    .create-draft {
      @include border--default-light-gray;
      @include text--semi-bold-big;
      height: 38px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }
  }
  .description {
    margin-top: 20px;
    color: $color--black-gray;
    font-size: 12px;
  }


}