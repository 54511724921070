@import '../../settings/main';

.search {
  @include border--default-gray;
  display: flex;
  height: 40px;
  width: 100%;
  min-width: 385px;
  align-items: center;
  background-color: white;

  &__input-block {
    position: relative;
    display: inherit;
    align-items: center;
    width: 100%;
    padding: 0 15px;

    input {
      width: 100%;
      padding-right: 10px;
    }

    input::placeholder {
      color: $color--dark-gray;
    }
  }
}

.search-box {
  position: absolute;
  left: 0;
  top: 32px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  z-index: 2;
}

.search-history {
  width: 100%;
  min-height: 100px;
  max-height: 350px;
  padding: 8px 15px;

  &__item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;

    &:hover {
      p {
        color: $color--yellow;
      }
    }
  }
}

.search-completions {
  display: flex;
  width: 700px;
  max-height: 600px;

  &__left {
    flex: 0 0 300px;
    max-width: 300px;
  }

  &__right {
    flex: 1 1 auto;
    border-left: 1px solid #eee;
  }

  &__category {
    cursor: pointer;
    padding: 4px 8px;

    &:hover {
      background-color: $color--yellow;
    }
  }

  &__offer {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    padding: 4px 8px;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__offer-image {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex: 0 0 auto;
  }

  &__offer-content {
    flex: 1 1 auto;
    overflow: hidden;
    font-size: 12px;
    padding-left: 12px;
  }

  &__offer-old-price {
    opacity: 0.6;
    text-decoration: line-through;
  }
}