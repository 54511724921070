@import '../../settings/main';

.full-product-card {

  &__left-col {
    flex: 0 0 484px;
    width: 484px;
    margin: 29px 0 29px 30px;
  }

  &__right-col {
    flex: 1 1 460px;
    padding-top: 20px;
    margin-right: 1em;
  }

  &__slider-photo-container {
    margin-top: 22px;
  }

  &__carousel-photo-container {
    margin-top: 25px;
  }

  &__product-position {
    position: relative;
    margin-top: 20px;
  }

  &__purchase {
    margin-top: 20px;
  }

  &__description-block {
    padding: 20px;

    h2 {
      @include text--semi-bold-large;
    }
  }

  &__description {
    margin-top: 12px;
    line-height: 22.4px;
  }
}

.specification {
  margin-top: 30px;

  &__title {
    @include text--semi-bold-large;
  }

  &__table-container {
    margin-top: 15px;
    @include border--default-gray;
    border-bottom: none;
  }


  &__table {
    line-height: 30px;
    border-spacing: 0;
    width: 100%;

    tr {

      td {
        border-bottom: $border--gray;

        &:first-child {
          width: 280px;
          color: $color--black-gray;
          padding-left: 11px;
        }

        &:last-child {
          @include text--semi-bold-big;
          padding-left: 23px;
        }
      }
    }
  }

  &__property_passive {
    color: $color--black-gray;
  }

}

.ico-container {
  display: flex;
  column-gap: 11.7px;
}

.product-head-block {
  border-bottom: $border--gray;

  &__category {
    @include text--regular-small;
    color: inherit;
    text-decoration: underline !important;
  }

  &__title {
    @include text--bold-xx-large;
    width: 419px;
    margin-top: 10px;
  }

  &__bread-crumbs {
    @include text--regular-small;
    color: $color--dark-gray;
    width: 406px;
    margin-top: 10px;
    margin-bottom: 20px;

    span {
      display: inline !important;
    }
  }
}

.product-position {
  display: flex;
  height: 130px;

  &__properties {
    display: inherit;
    flex-direction: column;
    justify-content: space-between;
    color: $color--dark-gray;
  }

  &__data {
    display: inherit;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
  }

  &__options {
    @include text--bold-small;
    position: absolute;
    right: 16px;
    margin-left: -72px;
  }

  &__certificate {
    background: url("../../assets/svg/DownloadIco.svg") no-repeat right;
    padding-right: 22px;
    text-align: right;
    margin-bottom: 15px;
    margin-right: 4px;
  }

  &__favorite {
    display: flex;
    align-items: center;
    column-gap: 7.62px;
    text-align: right;
    line-height: 20px;
    cursor: pointer;
  }
}

.purchase {
  display: flex;
  column-gap: 167px;
  height: 78px;
  padding: 0 20px;
  justify-content: space-between;
  border: $border--gray;
  border-radius: 4px 0 0 4px;
  border-right: none;
  background-color: $color--white-gray;

  &__price-title {
    display: inline-block;
    @include text--regular-small;
    color: $color--dark-gray;
  }

  &__price-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__price {
    @include text--bold-large;
    display: flex;
    align-items: center;
    column-gap: 10px;
    .price-card {
      @include text--bold;
      font-size: 18px;
    }
  }

  &__btn-block {
    display: flex;
    width: 200px;
    column-gap: 14px;
    align-items: center;
  }

  &__quantity-btn {
    width: 104px;
  }

  &__basket-btn {
    width: 80px;
  }

}
