@import '../../settings/main';

.catalog-toolbar {
  display: flex;
  height: 60px;
  border-bottom: $border--gray;
  background-color: $color--super-light-gray;
  align-items: center;
  column-gap: 15px;
  width: 100%;
  position: relative;
  z-index: 2;

  .dropdown-background {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: -60px;
    left: 0;
  }

  &__burger {
    display: flex;
    flex: none;
    width: 55px;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-right: $border--gray;
    cursor: pointer;
  }

  &__items {
    display: inherit;
    width: 100%;
    justify-content: space-between;
    column-gap: 15px;
  }

  .filters {
    display: flex;
    column-gap: 15px;
    width: 100%;
    padding-left: 15px;

    &__filter-direction {
      flex: none;
    }

    &__search-container {
      align-self: center;
    }

    .toggle-group {
      display: flex;
      flex: none;
      margin-left: 5px;
      column-gap: 20px;

      &__label {
        flex: none;
        margin-left: 7px;
        padding-bottom: 1px;
      }

      &__item {
        display: flex;
        flex: none;
        align-items: center;
      }

      &__i-img {
        width: 12px;
        height: 12px;
        margin-left: 5px;
        background: url("../../assets/svg/InfoIco.svg");
      }
    }
    @media screen and (max-width: 1850px) {
      .toggle-group__item:nth-child(6) {
        display: none;
      }
    }

    @media screen and (max-width: 1680px) {
      .toggle-group__item:nth-child(5) {
        display: none;
      }
    }

    @media screen and (max-width: 1560px) {
      .toggle-group__item:nth-child(4) {
        display: none;
      }
    }

    @media screen and (max-width: $laptop) {
      .toggle-group {
        display: none;
      }
      .catalog-toolbar__burger {
        background: white;
        border: none;
      }
    }
  }
  .tools {
    height: 40px;
    display: inherit;
    column-gap: 15px;

    &__download-btn {
      height: 100%;
    }


    .button-group {
      display: flex;
      @include border--default-gray;
      height: 100%;
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      &__table {
        width: 52px;
        height: 100%;
        background: url('../../assets/svg/Table.svg') no-repeat center;
        border-right: $border--light-gray;
      }

      &__grid {
        width: 52px;
        height: 100%;
        background: url('../../assets/svg/Grid.svg') no-repeat center;
      }

      &__btn_active {
        background-color: $color--super-light-gray;
        box-shadow: none;
      }
    }

  }


  .filter-btn {
    margin-right: 15px;
    width: 113px;

    button {
      height: 100%;
      width: 100%;
      text-align: right;
      padding-right: 20px;
      @include border--default-gray;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      background: white url("../../assets/svg/ico/filter.svg") no-repeat center left 20px;
    }
  }
}


